import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { Subscription, zip } from 'rxjs';
import { AuthProvider } from '../auth-provider.enum';
import { UserService } from 'src/app/core/user.service';
import { OrganizationService } from 'src/app/core/organization.service';
import { SlackNotificationService } from 'src/app/core/slack-notification.service';
import { AkerunService } from 'src/app/core/akerun.service';
import { LineWorksService } from 'src/app/core/lineworks.service';
import { MicrosoftEntraIdService } from 'src/app/core/microsoft-entra-id.service';

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit, OnDestroy {

    private userSubscription: Subscription;
    private queryParamMapSubscription: Subscription;
    private slackSubscription: Subscription;
    private akerunSubscription: Subscription;
    private lineworksSubscription: Subscription;
    private entraIdSubscription: Subscription;

    constructor(
        private userService: UserService,
        private organizationService: OrganizationService,
        private slackNotificationService: SlackNotificationService,
        private akerunService: AkerunService,
        private lineworksService: LineWorksService,
        private entraIdService: MicrosoftEntraIdService,
        private router: Router,
        private activatedRoute: ActivatedRoute,

    ) {

    }

    ngOnInit() {
        // cognito login callback
        this.queryParamMapSubscription = zip(this.activatedRoute.paramMap, this.activatedRoute.queryParamMap).subscribe(data => {
            if (data) {
                let params;
                let queryParams;
                [params, queryParams] = data;
                const provider = params.get('provider');
                const pathStateEncoded = params.get('state');
                const code = queryParams.get('code');
                const error = queryParams.get('error');
                const navigationExtras: NavigationExtras = {
                    state: (provider && code) ? { callback: { provider, code } } : undefined,
                };
                if (provider === AuthProvider.Cognito) {
                    this.router.navigate(['/login'], navigationExtras);
                } else if (provider === AuthProvider.Google) {
                    this.userSubscription = this.userService.current(true).subscribe(
                        user => {
                            this.organizationService.current().subscribe(
                                organization => {
                                    this.userService.updateUserByGoogleCalendarAuthorization(code, organization.name).subscribe(
                                        result => {
                                            navigationExtras.state.callback.notified = true;
                                            this.router.navigate(['/account_setting/calendar'], navigationExtras);
                                        }
                                    );
                                }
                            );
                        }
                    );
                } else if (provider === AuthProvider.SlackUser) {
                    if (error) {
                        let redirectPath = sessionStorage.getItem('redirectPath') || '/account_setting';
                        sessionStorage.removeItem('redirectPath');
                        redirectPath = decodeURIComponent(redirectPath);
                        const [path, params] = redirectPath.split(';');
                        if (params) {
                            this.router.navigate([path]);
                        } else {
                            this.router.navigate([redirectPath]);
                        }
                        return;
                    }
                    this.slackSubscription = this.slackNotificationService.accessToken(code, pathStateEncoded).subscribe(
                        result => {
                            navigationExtras.state.callback.slack = result;
                            if (pathStateEncoded) {
                                console.log(`base64decode: ${this.base64DecodeUrl(pathStateEncoded)}`);
                                const pathState = JSON.parse(atob(this.base64DecodeUrl(pathStateEncoded)));
                                console.log(`pathState:`, JSON.stringify(pathState));
                                if (JSON.stringify(pathState).indexOf('flow_id') !== -1) {
                                    this.router.navigate(['/organization_setting', pathState], navigationExtras);
                                } else if (JSON.stringify(pathState).indexOf('division_id') !== -1) {
                                    this.router.navigate(['/division', pathState], navigationExtras);
                                } else {
                                    this.router.navigate(['/user_setting/user', pathState], navigationExtras);
                                }
                            } else {
                                this.router.navigate(['/account_setting/profile'], navigationExtras);
                            }
                        }
                    );
                } else if (provider === AuthProvider.Akerun) {
                    this.akerunSubscription = this.akerunService.accessToken(code).subscribe(
                        result => {
                            navigationExtras.state.callback.akerun = result;
                            this.router.navigate(['/external_linkage'], navigationExtras);
                        }
                    );
                } else if (provider === AuthProvider.LineWorks) {
                    this.lineworksSubscription = this.lineworksService.accessToken(code).subscribe(
                        result => {
                            console.log(result);
                            navigationExtras.state.callback.lineworks = result;
                            this.router.navigate(['/external_linkage'], navigationExtras);
                        }
                    );
                } else if (provider === AuthProvider.MicrosoftEntraId) {
                    // http://localhost:4200/auth/callback/entraid?error=access_denied&error_subcode=cancel&state=12345
                    const error = queryParams.get('error');
                    if (error) {
                        console.error(`Microsoft Entra ID error: ${error}`);
                        this.router.navigate(['/external_linkage'], navigationExtras);
                        return;
                    }
                    this.entraIdSubscription = this.entraIdService.upsert({code}).subscribe(
                        result => {
                            console.log(result);
                            navigationExtras.state.callback.entraid = result;
                            this.router.navigate(['/external_linkage'], navigationExtras);
                        }
                    );
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.queryParamMapSubscription) {
            this.queryParamMapSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.slackSubscription) {
            this.slackSubscription.unsubscribe();
        }
        if (this.akerunSubscription) {
            this.akerunSubscription.unsubscribe();
        }
        if (this.lineworksSubscription) {
            this.lineworksSubscription.unsubscribe();
        }
        if (this.entraIdSubscription) {
            this.entraIdSubscription.unsubscribe();
        }
    }

    /**
     * Use this to recreate a Base64 encoded string that was made URL friendly
     * using Base64EncodeurlFriendly.
     * '-' and '_' are replaced with '+' and '/' and also it is padded with '+'
     *
     * @param str the encoded string
     * @returns the URL friendly encoded String
     */
    private base64DecodeUrl(str) {
        let paddingStringLength = (str.length % 4) === 0 ? 0 : (4 - (str.length % 4));
        str = (str + '===').slice(0, str.length + paddingStringLength);
        return str.replace(/-/g, '+').replace(/_/g, '/');
    }
}
